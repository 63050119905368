import React from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import DevPage from "../pages/DevPage";
import I18nPage from "../pages/I18nPage";
import Kisfaludy from "../pages/kisfaludy";

export default function PageRouting() {
  const { t } = useTranslation();

  return (
    <Switch>
      <Route exact path="/" render={() => <I18nPage path="page.main" />} />
      <Route
        path={t("url.contact")}
        render={() => <I18nPage path="page.contact" />}
      />
      <Route
        path={t("url.services")}
        render={() => <I18nPage path="page.services" />}
      />
      <Route
        path={t("url.gallery")}
        render={() => <I18nPage path="page.gallery" />}
      />
      <Route
        path={t("url.rooms")}
        render={() => <I18nPage path="page.rooms" />}
      />
      <Route path={"/kisfaludy"} component={Kisfaludy} />
      <Route path={"/dev"} component={DevPage} />
    </Switch>
  );
}
