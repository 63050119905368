import React from "react";
import LanguageSelector from "../components/LanguageSelector";

export default function DevPage() {
  return (
    <div>
      <LanguageSelector />
    </div>
  );
}
