import { ChakraProvider } from "@chakra-ui/react";
import "format-unicorn";
import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Layout from "../layout";
import customTheme from "./customTheme";
import PageRouting from "./PageRouting";

function App() {
  return (
    <Suspense fallback="...">
      <ChakraProvider theme={customTheme}>
        <Router>
          <Layout>
            <PageRouting />
          </Layout>
        </Router>
      </ChakraProvider>
    </Suspense>
  );
}

export default App;
