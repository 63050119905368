import * as yup from "yup";

export const validationSchema_1 = (t) =>
  yup.object().shape({
    children: yup.number().min(0).max(3),
    adults: yup
      .number()
      .min(1, "A felnőttek száma hibás.")
      .max(4, "A felnőttek száma hibás."),
    children_ages: yup
      .array()
      .of(
        yup
          .number()
          .required(t("offer-form.participant_children_ages_error"))
          .min(0, "Hibás életkor")
          .max(16, "Hibás életkor")
      ),
  });

export const validationSchema_2 = (t) =>
  yup.object().shape({
    name: yup.string().required(t("offer-form.modal_name_required")),
    email: yup
      .string()
      .email(t("offer-form.modal_email_matches"))
      .required(t("offer-form.modal_email_required")),
    phone: yup
      .string()
      .matches(/^[0-9-+]+$/, t("offer-form.modal_phone_matches"))
      .min(8, t("offer-form.modal_phone_min"))
      .required(t("offer-form.modal_phone_required")),
    message: yup.string(),
    room: yup
      .string()
      .oneOf([
        "standard_a",
        "standard_b",
        "deluxe_a",
        "deluxe_b",
        "family_apartman",
      ]),
    acceptterms: yup.bool().isTrue(t("offer-form.modal_acceptterms_required")),
  });
