/*import {
  Box,
  Button,
  HStack,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  VStack,
} from "@chakra-ui/react";*/
import * as chakra from "@chakra-ui/react";
import { AspectRatio, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Gallery from "./components/Gallery";
import JsonRenderer from "./components/JsonRenderer";
import useTitle from "./hooks/useTitle";

function I18nRenderer({ path, components }) {
  const { t } = useTranslation();
  const render = t(path, { returnObjects: true });
  return (
    <JsonRenderer
      render={render}
      components={components || jsonRendererComponents}
    />
  );
}
I18nRenderer.raw_call = false;

function DocTitle({ children }) {
  useTitle(children);

  return null;
}

function GoogleMap() {
  return (
    <Box bg="gray.200">
      <AspectRatio ratio={16 / 11}>
        <iframe
          width="100%"
          height="100%"
          src="https://maps.google.com/maps?q=20%20Horv%C3%A1th%20Mih%C3%A1ly%20utca,%20Balatonf%C3%BCred,%20Magyarorsz%C3%A1g&t=&z=15&ie=UTF8&iwloc=&output=embed"
          frameBorder="0"
          scrolling="no"
          title="google-map"
        />
      </AspectRatio>
      <style children=".mapouter{position:relative;text-align:right;height:500px;width:803px;}" />
    </Box>
  );
}

export const jsonRendererComponents = {
  ...chakra,
  Link: Link,
  log: (props) => {
    console.log(props);
    return null;
  },
  default: chakra.Box,
  i18n: I18nRenderer,
  "document-title": DocTitle,
  GoogleMap,
  Gallery,
};
