export const services_render = [
  { component: "document-title", children: "Faciliteiten" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        children: "Faciliteiten",
        component: "Text",
        as: "h2",
        fontSize: "2xl",
        mb: 4,
        color: "brand.red",
      },
      {
        children: "Algemeen",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Roken is niet toegestaan",
            "Betaald parkeren",
            "24-uursreceptie",
            "VIP in- en uitchecken",
          ],
          [
            "Huisdieren zijn niet toegestaan",
            "Kamers/faciliteiten voor mindervaliden",
            "Beveiliging",
            "Brandblussers",
          ],
        ],
      },
      {
        children: "Sport en recreatie",
        component: "separator",
      },
      {
        component: "info-block",
        children: [["Vermaak", "Bar/ lounge"], ["Fietsen"]],
      },
      {
        children: "Diensten",
        component: "separator",
      },
      {
        component: "info-block",
        children: ["Luchthavenshuttle", "Fietsverhuur"],
      },
      {
        children: "Kamervoorzieningen",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          ["Airconditioning", "Verwarming", "Mini bar", "Terras", "Eettafel"],
          [
            "Wastafel",
            "Flat-screen TV",
            "Dvd's/ Video's voor kinderen",
            "Vloerbedekking",
          ],
        ],
      },
      {
        component: "i18n",
        path: "page.service_az",
      },
    ],

    __define: {
      separator: {
        w: "100%",
        color: "gray",
        borderBottom: "1px solid gray",
      },
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "info-block": {
        w: "100%",
        mb: 4,
        component: "Stack",
        direction: "row",
        children: {
          component: "wrap",
          wrap: {
            w: "50%",
            children: {
              component: "ul",
            },
          },
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
