export const services_render = [
  { component: "document-title", children: "Faciliteter" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        children: "Faciliteter",
        component: "Text",
        as: "h2",
        fontSize: "2xl",
        mb: 4,
        color: "brand.red",
      },
      {
        children: "General",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Rygning forbudt på alle fælles- og privatområder",
            "Betalingsparkering",
            "24-timers Reception",
            "Privat ind-/ udtjekning",
          ],
          [
            "Kæledyr ikke tilladt",
            "Handicapværelser/ faciliteter",
            "Vagtservice",
            "Brandslukkere",
          ],
        ],
      },
      {
        children: "Sport og Fritid",
        component: "separator",
      },
      {
        component: "info-block",
        children: [["Underholdning", "Bar/ Lounge område"], ["Cykling"]],
      },
      {
        children: "Services",
        component: "separator",
      },
      {
        component: "info-block",
        children: ["Lufthavnsbus", "Cykeludlejning"],
      },
      {
        children: "Bekvemmeligheder på værelset",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          ["Klimaanlæg", "Varme", "Minibar", "Terrasse", "Spisebord"],
          [
            "Vask på værelset",
            "Fladskærms TV",
            "Dvd'er/ Videoer for børn",
            "Tæppebelagt gulv",
          ],
        ],
      },
      {
        component: "i18n",
        path: "page.service_az",
      },
    ],

    __define: {
      separator: {
        w: "100%",
        color: "gray",
        borderBottom: "1px solid gray",
      },
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "info-block": {
        w: "100%",
        mb: 4,
        component: "Stack",
        direction: "row",
        children: {
          component: "wrap",
          wrap: {
            w: "50%",
            children: {
              component: "ul",
            },
          },
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
