import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { jsonRendererComponents } from "../../jsonRendererComponents";
import JsonRenderer from "../JsonRenderer";
import { validationSchema_2 } from "./validation";

export default function OfferModal({ data, onClose }) {
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const vSchema = useMemo(() => validationSchema_2(t), [t]);

  return (
    <Modal isOpen={!!data} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <Formik
          validationSchema={vSchema}
          initialValues={{
            name: "",
            email: "",
            message: "",
            phone: "",
            room: "standard_a",
            acceptterms: false,
          }}
          onSubmit={(values) => {
            axios
              .post("/api/offer-request", {
                ...values,
                ...data,
                lang: i18n.language,
              })
              .then((r) => {
                onClose(true);
              })
              .catch((r) =>
                toast({
                  title: "Hiba a kérés feldolgozása során",
                  description: r.message,
                  status: "error",
                  duration: 9000,
                  isClosable: true,
                })
              );
          }}
        >
          <Form>
            <ModalHeader>{t("offer-form.modal_title")}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl>
                <FormLabel>{t("offer-form.modal_name")}</FormLabel>
                <Field as={Input} flex={1} name="name" />
                <ErrorMessage component={Text} name="name" color="brand.red" />
              </FormControl>
              <FormControl>
                <FormLabel>{t("offer-form.modal_email")}</FormLabel>
                <Field as={Input} flex={1} name="email" />
                <ErrorMessage component={Text} name="email" color="brand.red" />
              </FormControl>
              <FormControl>
                <FormLabel>{t("offer-form.modal_phone")}</FormLabel>
                <Field as={Input} flex={1} name="phone" />
                <ErrorMessage component={Text} color="brand.red" name="phone" />
              </FormControl>
              <FormControl>
                <FormLabel>{t("offer-form.modal_room")}</FormLabel>
                <Field as={Select} flex={1} name="room">
                  <option value="deluxe_a">Deluxe A</option>
                  <option value="deluxe_b">Deluxe B</option>
                  <option value="family_apartman">Family Apartnam</option>
                  <option value="standard_a">Standard A</option>
                  <option value="standard_b">Standard B</option>
                </Field>
                <ErrorMessage component={Text} color="brand.red" name="room" />
              </FormControl>
              <FormControl>
                <FormLabel>{t("offer-form.modal_message")}</FormLabel>
                <Field as={Input} flex={1} name="message" />
                <ErrorMessage
                  component={Text}
                  color="brand.red"
                  name="message"
                />
              </FormControl>
              <FormControl>
                <Field
                  as={Checkbox}
                  flex={1}
                  name="acceptterms"
                  type="checkbox"
                  required
                >
                  <JsonRenderer
                    render={t("offer-form.modal_acceptterms", {
                      returnObjects: true,
                    })}
                    components={jsonRendererComponents}
                  />
                </Field>
                <ErrorMessage
                  component={Text}
                  color="brand.red"
                  name="acceptterms"
                />
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <HStack w="100%" justify="space-between">
                <Button
                  colorScheme="brand.dred-scheme"
                  type="button"
                  onClick={() => onClose(false)}
                >
                  {t("offer-form.modal_btn_cancel")}
                </Button>
                <Button colorScheme="brand.dred-scheme" type="submit">
                  {t("offer-form.modal_btn_ok")}
                </Button>
              </HStack>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalContent>
    </Modal>
  );
}
