import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  Button,
  Center,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SuccessModal({ onClose }) {
  const { t } = useTranslation();

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("offer-form.success_title")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Center>
            <CheckCircleIcon fontSize="9xl" mb={4} color="green.400" />
          </Center>
          <Text fontSize="xl" textAlign="center">
            {t("offer-form.success_text")}
          </Text>
        </ModalBody>
        <ModalFooter>
          <HStack w="100%" justify="flex-end">
            <Button
              colorScheme="brand.dred-scheme"
              type="button"
              onClick={onClose}
            >
              {t("offer-form.success_btn_ok")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
