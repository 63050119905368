import { CalendarIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import DatePickerBase from "../DatePickerBase";
import PopoverInput from "../PopoverInput";
import OfferFormParticipantInput from "./OfferFormParticipantInput";
import OfferModal from "./OfferModal";
import SuccessModal from "./SuccessModal";

export default function OfferForm() {
  const [ordata, setOrdata] = useState(null);
  const [stage, setStage] = useState(null);
  const { t } = useTranslation();
  return (
    <>
      {stage === "success" && <SuccessModal onClose={() => setStage(null)} />}
      {stage === "user" && (
        <OfferModal
          data={ordata}
          onClose={(success) => {
            console.log("offer-closed", success);
            setOrdata(null);
            setStage(success ? "success" : null);
          }}
        />
      )}
      <Formik
        initialValues={{
          arrival: new Date(),
          leaving: new Date(+new Date() + 1000 * 60 * 60 * 24),
          adults: 2,
          children: 0,
          children_ages: [],
        }}
        onSubmit={(values) => {
          console.log("submitting", values);
          setOrdata(values);
          setStage("user");
        }}
      >
        {(form) => (
          <Form>
            <VStack spacing={4} height="100%" justify="space-between">
              <Text
                fontSize="1.25em"
                py={3}
                align="center"
                fontWeight="bold"
                textTransform="uppercase"
                children={t("offer-form.title")}
              />
              <FormControl id="email">
                <FormLabel>{t("offer-form.arrival")}</FormLabel>
                <HStack spacing={3}>
                  <Field
                    as={DatePickerBase}
                    flex={1}
                    name="arrival"
                    bg="white"
                    dateFormat="yyyy-MM-dd"
                    minDate={new Date()}
                    id="arrival"
                  />
                  <label htmlFor="arrival">
                    <CalendarIcon fontSize="1.75em" />
                  </label>
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>{t("offer-form.leaving")}</FormLabel>
                <HStack spacing={3}>
                  <Field
                    as={DatePickerBase}
                    flex={1}
                    name="leaving"
                    bg="white"
                    dateFormat="yyyy-MM-dd"
                    minDate={form.values.arrival}
                    id="leaving"
                  />
                  <label htmlFor="leaving">
                    <CalendarIcon fontSize="1.75em" />
                  </label>
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel>{t("offer-form.participant")}</FormLabel>
                <PopoverInput
                  value={
                    form.values.children > 0
                      ? t("offer-form.participant_format_2")?.formatUnicorn(
                          form.values
                        )
                      : t("offer-form.participant_format_1")?.formatUnicorn(
                          form.values
                        )
                  }
                  title={<Text children={t("offer-form.participant_title")} />}
                >
                  <OfferFormParticipantInput form={form} />
                </PopoverInput>
              </FormControl>
              <Box pt={3} w="100%">
                <Button w="100%" colorScheme="brand.dred-scheme" type="submit">
                  {t("offer-form.btn_ok_1")}
                </Button>
              </Box>
            </VStack>
          </Form>
        )}
      </Formik>
    </>
  );
}
