import da from "./da/index";
import de from "./de/index";
import en from "./en/index";
import hu from "./hu/index";
import nl from "./nl/index";
import pl from "./pl/index";
import sv from "./sv/index";

const languages = {
  "hu-HU": { translation: hu },
  "en-GB": { translation: en },
  "pl-PL": { translation: pl },
  "de-DE": { translation: de },
  "nl-NL": { translation: nl },
  "da-DK": { translation: da },
  "sv-SE": { translation: sv },
};
export default languages;
