export const services_render = [
  { component: "document-title", children: "Udogodnienia" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        children: "Udogodnienia",
        component: "Text",
        as: "h2",
        fontSize: "2xl",
        mb: 4,
        color: "brand.red",
      },
      {
        children: "O hotelu",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Całkowity zakaz palenia",
            "Płatny parking",
            "Recepcja czynna 24h na dobę",
            "Indywidualne zameldowanie/wymeldowanie",
          ],
          [
            "Zakaz wprowadzania zwierząt",
            "Pokoje lub wyposażenie dla niepełnosprawnych",
            "Ochrona",
            "Gaśnice",
          ],
        ],
      },
      {
        children: "Sport i rozrywka",
        component: "separator",
      },
      {
        component: "info-block",
        children: [["Rozrywka", "Bar/ Poczekalnia"], ["Jazda na rowerze"]],
      },
      {
        children: "Usługi",
        component: "separator",
      },
      {
        component: "info-block",
        children: ["Autobus na lotnisko", "Wypożyczalnia rowerów"],
      },
      {
        children: "Udogodnienia w pokoju",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          ["Klimatyzacja", "Ogrzewanie", "Minibar", "Taras", "Stół"],
          [
            "Zlew",
            "Telewizor LCD",
            "DVD/wideo dla dzieci",
            "Podłoga z dywanem",
          ],
        ],
      },
      {
        component: "i18n",
        path: "page.service_az",
      },
    ],

    __define: {
      separator: {
        w: "100%",
        color: "gray",
        borderBottom: "1px solid gray",
      },
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "info-block": {
        w: "100%",
        mb: 4,
        component: "Stack",
        direction: "row",
        children: {
          component: "wrap",
          wrap: {
            w: "50%",
            children: {
              component: "ul",
            },
          },
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
