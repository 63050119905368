export const gallery_render = [
  { component: "document-title", children: "Galleri" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        component: "Text",
        children: "Galleri",
        color: "brand.red",
        fontSize: "3xl",
        mb: 4,
      },
      {
        component: "Gallery",
        apiKey: "main",
        templateColumns: {
          base: "repeat(2, 1fr)",
          sm: "repeat(3, 1fr)",
          md: "repeat(4, 1fr)",
          lg: "repeat(5, 1fr)",
        },
      },
    ],
  },
];
