export const services_render = [
  { component: "document-title", children: "Faciliteter" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        children: "Faciliteter",
        component: "Text",
        as: "h2",
        fontSize: "2xl",
        mb: 4,
        color: "brand.red",
      },
      {
        children: "Beskrivning",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Helt rökfritt",
            "Parkering (mot avgift)",
            "24-timmars reception",
            "VIP-incheckning/-utcheckning",
          ],
          [
            "Inga husdjur",
            "Rum/faciliteter för funktionshindrade",
            "Säkerhet",
            "Brandsläckare",
          ],
        ],
      },
      {
        children: "Sport och fritid",
        component: "separator",
      },
      {
        component: "info-block",
        children: [["Underhållning", "Bar/lounge"], ["Cykling"]],
      },
      {
        children: "Service",
        component: "separator",
      },
      {
        component: "info-block",
        children: ["Flygplatstransfer", "Cykeluthyrning"],
      },
      {
        children: "Rummets bekvämligheter",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Luftkonditionering",
            "Uppvärmning",
            "Minibar",
            "Terrass",
            "Matbord",
          ],
          ["Handfat", "LCD-TV", "DVD/videor för barn", "Heltäckningsmatta"],
        ],
      },
      {
        component: "i18n",
        path: "page.service_az",
      },
    ],

    __define: {
      separator: {
        w: "100%",
        color: "gray",
        borderBottom: "1px solid gray",
      },
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "info-block": {
        w: "100%",
        mb: 4,
        component: "Stack",
        direction: "row",
        children: {
          component: "wrap",
          wrap: {
            w: "50%",
            children: {
              component: "ul",
            },
          },
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
