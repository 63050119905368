import { Box } from "@chakra-ui/react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./date-picker.css";

const DatePickerBase = ({
  onChange,
  isClearable = false,
  showPopperArrow = false,
  value,
  ...props
}) => {
  return (
    <Box flex={1} color="black">
      <ReactDatePicker
        type="date"
        selected={
          !value ? null : typeof value === "string" ? new Date(value) : value
        }
        onChange={
          onChange &&
          ((v) => onChange({ target: { value: v, name: props.name } }))
        }
        isClearable={isClearable}
        showPopperArrow={showPopperArrow}
        {...props}
      />
    </Box>
  );
};

export default DatePickerBase;
