export const main_render = [
  { component: "document-title", children: "Hotel info" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "Located in Balatonfured, the low-budget Thomas Panzio Hotel is 65 km from Heviz-Balaton airport. The venue comprises 15 rooms.",
        "Truncated Tower is 4.3 km from the accommodation, while Fisherman and the Ferryman Guard Statue is just around the corner. Jokai kilato is settled 15 minutes’ walk away.In the vicinity of the hotel there is Tihanyi Kilato.",
        "Most rooms at the property have a heating system, a private balcony and a wardrobe.",
        "There is a bar for guests to enjoy their meal. Nearby restaurants, including Peron Pizzeria & Pub, Halaszkert and Giorgi Pizzeria and Restaurant are 5 minutes walk from the venue.Szechenyi setany bus stop is located nearby.The hotel also features a designated smoking place and a carpark.",
        {
          color: "brand.red",
          children: "Important information",
          fontSize: "xl",
        },
        [
          { component: "b", children: "Check-in: " },
          "from 15:00-18:00 hours",
          { component: "br" },
          { component: "b", children: "Check-out: " },
          "until 08:00-10:00 hours",
        ],
        [
          { component: "b", children: "Children and extra beds" },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Older children/adults may stay for free in an extra bed.",
                "All children under the age of 2 may stay for free in a cot.",
              ],
            },
          },
        ],
        "Guests are required to show a photo identification and credit card upon check-in. Please note that all Special Requests are subject to availability and additional charges may apply. In response to Coronavirus (COVID-19), additional safety and sanitation measures are currently in effect at this property.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
