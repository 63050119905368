export const contact_render = [
  { component: "document-title", children: "Placering" },
  {
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
    component: "Box",
    flex: 1,
    children: [
      {
        component: "Text",
        children: "Placering",
        color: "brand.red",
        fontSize: "3xl",
        mb: 4,
      },
      {
        mb: 4,
        component: "ul",
        children: [
          [
            { component: "b", children: "Adresse: " },
            "20 Horváth Mihály utca, Balatonfüred, Magyarország",
          ],
          [{ component: "b", children: "Telefon: " }, "+36-30-163-6556"],
          [{ component: "b", children: "Email: " }, "info@thomas-panzio.com"],
        ],
      },
      {
        component: "Text",
        children: "Location",
        color: "brand.red",
        fontSize: "3xl",
        mb: 4,
      },
      { component: "GoogleMap", id: "map" },
    ],
  },
];
