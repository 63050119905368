import {
  Box,
  Center,
  Container,
  HStack,
  Image,
  Stack,
  Text,
} from "@chakra-ui/react";
import { default as React } from "react";
import { useTranslation } from "react-i18next";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import LanguageSelector from "../components/LanguageSelector";
import OfferForm from "../components/OfferForm/index";
import RemoteCarousel from "../components/RemoteCarousel";
import LeftBar from "./LeftBar";
import logo from "./logo.svg";
import ToggleMenu from "./ToggleMenu";

export default function Layout({ children }) {
  const { t } = useTranslation();

  return (
    <Box w="100%" minH="100vh" bg="brand.gray" display="flex" flexDir="column">
      {/* Logo bar */}
      <Box px={{ base: 2, lg: 0 }} w="100%" mx="auto" bg="white">
        <Container
          w="100%"
          //overflow="hidden"
          maxW="container.lg"
          pos="relative"
          color="brand.red"
          fontWeight="bold"
        >
          <Box
            display={{ base: "block", md: "none" }}
            h={0}
            position="absolute"
            right="0"
            top={2}
            alignItems="flex-end"
          >
            <LanguageSelector />
          </Box>
          <Center w="100%" h="3em" top={0} left={0} p={1}>
            <Image alt="logo" src={logo} h="100%" />
          </Center>
          <Stack
            w="100%"
            h="100%"
            justify="space-between"
            position={{ base: "relative", md: "absolute" }}
            direction={{ base: "column", md: "row" }}
            top={0}
            left={0}
          >
            <Text
              textAlign="center"
              textTransform="uppercase"
              display={{ md: "flex" }}
              alignItems="center"
            >
              {t("layout.name")}
            </Text>
            <HStack display={{ base: "none", md: "flex" }}>
              <Text textAlign="center" alignItems="center">
                {t("layout.address")}
              </Text>
              <LanguageSelector />
            </HStack>
            <ToggleMenu />
          </Stack>
        </Container>
      </Box>
      {/* Carousel */}
      <Container p={0} w="100%" maxW="container.lg">
        <HStack bg="brand.red" align="top" spacing={0} wrap="wrap">
          <Box flex={{ md: 1 }}>
            <RemoteCarousel apiKey="main" />
          </Box>
          <Box w={{ base: "100%", md: "17em" }} color="white" p={4}>
            <OfferForm />
          </Box>
        </HStack>
      </Container>
      {/* Page content area */}
      <Container
        p={0}
        maxW="container.lg"
        flex={1}
        bg="white"
        overflow="hidden"
      >
        <HStack alignItems="top" p={0} w="100%">
          <Box
            p={3}
            w={{ base: "100%", md: "11em" }}
            display={{ base: "none", md: "block" }}
          >
            <LeftBar />
          </Box>
          <Box p={3} flex={1}>
            {children}
          </Box>
        </HStack>
      </Container>
      <Container
        maxW="container.lg"
        color="gray.800"
        fontSize="xs"
        p={1}
        overflow="hidden"
        textAlign="center"
      >
        2021 © Thomas Panzió. All rights reserved
      </Container>
    </Box>
  );
}
