export const main_render = [
  { component: "document-title", children: "Hotelinformation" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "Thomas Panzio Bed and Breakfast er et budget sted for at bo i Balatonfüred. Denne indkvartering består af 15 værelser.",
        "Bed & Breakfast ligger cirka 10 minutters kørsel til Magyar Kiralyok Panoptikuma & Kalozmuzeum. Stedet placeret kun 4,7 km fra Madarles Csopak.Jokai kilato ligger også i nærheden af hotellet. Gæster kan også spadsere til Jokai Mor Memorial House.",
        "Værelserne har centralvarme, egen balkon og et spisekammer samt senge med topmadras, duntæppe og dunpuder.",
        "Gæsterne kan få morgenmad i baren hver morgen. Peron Pizzéria & Pub og Halaszkert er 200 meter væk.Det er mindre end 5 minutters gang fra Balatonfured togstation.Hotellet har også et særligt rygeområde og parkering.",
        {
          color: "brand.red",
          children: "Vigtig information",
          fontSize: "xl",
        },
        [
          { component: "b", children: "Check-in: " },
          "fra 15:00-18:00 timer",
          { component: "br" },
          { component: "b", children: "Check-ud: " },
          "indtil 08:00-10:00 timer",
        ],
        [
          { component: "b", children: "Børn og ekstra senge" },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Større børn/voksne kan overnatte gratis i en ekstra seng.",
                "Alle børn under 2 år kan overnatte gratis i en barneseng.",
              ],
            },
          },
        ],
        "Gæsterne bedes fremvise gyldigt billed-id og kreditkort ved indtjekning. Bemærk venligst, at hvorvidt særlige forespørgsler kan imødekommes, afhænger af tilgængelighed på ankomsttidspunktet, samt at der kan pålægges et gebyr. På grund af coronavirus (COVID-19) er der i øjeblikket indført yderligere sikkerheds- og hygiejneforanstaltninger på dette overnatningssted.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
