import { extendTheme } from "@chakra-ui/react";

const customTheme = {
  fonts: {
    body: '"Open Sans", system-ui, sans-serif',
    heading: '"Open Sans", Georgia, serif',
    mono: '"Open Sans", Menlo, monospace',
  },
  colors: {
    brand: {
      red: "#820026",
      "dred-scheme": {
        600: "#38010c",
        500: "#510214",
      },
      dred: "#510214",
      gray: "#bac9cc",
      100: "#f7fafc",
      900: "#1a202c",
    },
  },
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: "brand.red",
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: "brand.red",
      },
    },
  },
  shadows: {
    outline: "0 0 0 3px rgba(130, 0, 38, 0.6)",
  },
};

export default extendTheme(customTheme);
