import { Box, Image, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import React from "react";
import banner from "../layout/wow_banner_fekvo_szoveg.png";

export default function Kisfaludy() {
  return (
    <Box w="100%">
      <Text fontSize="3xl" color="brand.red" mb={4}>
        Kisfaludy program
      </Text>
      <Text fontSize="xl" color="gray.800" mb={4}>
        Thomas panzió kapacitásbővítése, valamint minőségi és szolgáltatási
        színvonalának fejlesztése.
      </Text>
      <Text mb={2}>
        A pályázat keretében energetikai fejlesztéseket hajtottunk végre és
        napelemekkel láttuk el az épületet. Fejlesztettük szobáink
        felszereltségét és színvonalát, valamint új sportolási lehetőségeket
        teremtettünk vendégeink számára.
      </Text>
      <Text as="b" mb={2}>
        A támogatott projekt a Kisfaludy Turisztikai Fejlesztési Program
        keretében valósul meg.
      </Text>
      <Box mt={4} mb={4}>
        <Text color="brand.red" fontSize="2xl">
          Pályázat részletei
        </Text>
        <UnorderedList ml={6}>
          <ListItem>
            <b>Kedvezményezett: </b>
            THOMAS Kft.
          </ListItem>
          <ListItem>
            <b>Projekt: </b>
            Thomas panzió kapacitásbővítése, valamint minőségi és szolgáltatási
            színvonalának fejlesztése.
          </ListItem>
          <ListItem>
            <b>Pályázat azonosító: </b>
            TFC-1-1-1-2017-2018-00483
          </ListItem>
          <ListItem>
            <b>Támogatás összege: </b>
            40 075 087 forint
          </ListItem>
          <ListItem>
            <b>Támogatás mértéke: </b>
            70%
          </ListItem>
          <ListItem>
            <b>Befejezés: </b>
            2020. december 31.
          </ListItem>
        </UnorderedList>
      </Box>
      <Image src={banner} w="100%" mx="auto" mb={4} />
    </Box>
  );
}
