export const service_az_render = {
  __define: {
    ul: {
      component: "UnorderedList",
      children: {
        component: "wrap",
        wrap: "ListItem",
      },
    },
  },
  children: [
    {
      children: "Breakfast (for a fee)",
      component: "Text",
      as: "h2",
      fontSize: "2xl",
      mt: 4,
      color: "brand.red",
    },
    {
      component: "p",
      children:
        "In our Pansion we have only way to serve cold breakfast : sandwich: with ham or salami or cheese; allof varialble garnished with greens.",
    },
    {
      component: "p",
      children: "Downstairs at the Kebab Bar you can ask for hot fast food. ",
    },
    {
      children: "Service Information",
      component: "Text",
      as: "h2",
      fontSize: "2xl",
      mt: 4,
      color: "brand.red",
    },
    {
      component: "ul",
      children: [
        [
          {
            component: "b",
            children: "24 hours luggage strorage - ",
          },
          "in/out guest, indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Bedding - ",
          },
          "changing 2 times a week",
        ],
        [
          {
            component: "b",
            children: "Shoe polisher - ",
          },
          " at the reception desk",
        ],
        [
          {
            component: "b",
            children: "Smokeing - ",
          },
          " only ont he signed places ",
        ],
        [
          {
            component: "b",
            children: "Umbrella - ",
          },
          "indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Baby chair - ",
          },
          " free of charge, indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Restaurant - ",
          },
          "Kebab bar on ground floor from 8.00-10.00 am",
        ],
        [
          {
            component: "b",
            children: "Paying instruments - ",
          },
          "cash (forint), credit card, debit card",
        ],
        [
          {
            component: "b",
            children: "Hair dryer - ",
          },
          "indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Internet, wi-fi  - ",
          },
          "ll rooms and common areas free of charge; access code: BJH4H6MRLBR; HUAWEIB535-A723",
        ],
        [
          {
            component: "b",
            children: "Rent a bike - ",
          },
          "we have 10 piece of electrical bike, indicate your needs at reception and ask the fees",
        ],
        [
          {
            component: "b",
            children: "Late check out - ",
          },
          "compensation is required, indicate your needs at reception – last hour of check out17.00",
        ],
        [
          {
            component: "b",
            children: "Check out - ",
          },
          "10.00 am",
        ],
        [
          {
            component: "b",
            children: "Key card - ",
          },
          "we have only security key locks",
        ],
        [
          {
            component: "b",
            children: "Air condition - ",
          },
          "heat/cool/ventilation function in all rooms",
        ],
        [
          {
            component: "b",
            children: "Printing service - ",
          },
          "compensation is required; indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Pillow - ",
          },
          "ask the staff for it",
        ],
        [
          {
            component: "b",
            children: "Extra bed, children bed, children traveling bed - ",
          },
          "free of charge, indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Programs - ",
          },
          "ask it at the reception desk ",
        ],
        [
          {
            component: "b",
            children: "Parking - ",
          },
          " in limited mode at the own parking places",
        ],
        [
          {
            component: "b",
            children: "Radio - ",
          },
          "across the TV",
        ],
        [
          {
            component: "b",
            children: "Reception - ",
          },
          "opening hours : 8.00-22.00",
        ],
        [
          {
            component: "b",
            children: "Regional dishes, wines - ",
          },
          "– front of us at the restaurant",
        ],
        [
          {
            component: "b",
            children: "Beach stuffs - ",
          },
          "ask from the staff",
        ],
        [
          {
            component: "b",
            children: "Mobile for the room - ",
          },
          "ndicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Cleaning - ",
          },
          "every day",
        ],
        [
          {
            component: "b",
            children: "Extra blankets, pillows - ",
          },
          " indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "TV - ",
          },
          " all rooms have SMART TV with remote control",
        ],
        [
          {
            component: "b",
            children: "Bathroom products - ",
          },
          "indicate your needs at reception",
        ],
        [
          {
            component: "b",
            children: "Towels - ",
          },
          "all room are equiped, for change ask the staff",
        ],
        [
          {
            component: "b",
            children: "Sewing set - ",
          },
          " indicate your needs at reception",
        ],
      ],
    },
  ],
};
