export const main_render = [
  { component: "document-title", children: "Hotelinformationen" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "Das Bed and Breakfast Thomas Panzio befindet sich 300 Meter vom Bahnhof Balatonfured entfernt und bietet einen schnellen Zugang zu Jokai kilato. Die Unterkunft umfasst 15 Zimmer. Ein Privatparkplatz ist vor Ort verfügbar.",
        "Die wichtigsten Sehenswürdigkeiten von Balatonfüred wie Tihanyi Babamuzeum Kft. und Papsokai church liegen in der Nähe. Es ist nur 10 Minuten Fußweg von der Bushaltestelle Széchenyi sétány entfernt und bietet Verbindungen zu Jokai Mor Memorial House.",
        "edes Hotelzimmer verfügt über einen privaten Balkon, Zentralheizung und einen elektronischen Safe.",
        "Die Unterkunft liegt in 200 Meter Entfernung von Restaurant Peron Pizzéria & Pub und Halaszkert, die eine reichhaltige Auswahl an feinen Speisen anbieten.Der nächstgelegene Flughafen ist FlyBalaton, der sich in 65 km Entfernung befindet.",
        {
          color: "brand.red",
          children: "Wichtige Informationen",
          fontSize: "xl",
        },
        [
          { component: "b", children: "Check-in: " },
          "von 15:00-18:00 Uhr",
          { component: "br" },
          { component: "b", children: "Check-out: " },
          "bis 08:00-10:00 Uhr",
        ],
        [
          { component: "b", children: "Kinder- und Zustellbetten" },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Für Ältere Kinder/Erwachsene ist der Aufenthalt in einem Zustellbett kostenlos.",
                "ür Alle Kinder unter 2 Jahren ist der Aufenthalt in einem Gitterbett kostenlos.",
              ],
            },
          },
        ],
        "Beim Check-in müssen Sie einen Lichtbildausweis sowie eine Kreditkarte vorlegen. Sonderwünsche unterliegen der Verfügbarkeit und sind gegebenenfalls mit einem Aufpreis verbunden. Wegen des Coronavirus (COVID-19) wurden in dieser Unterkunft zusätzliche Sicherheits- und Hygienemaßnahmen unternommen.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
