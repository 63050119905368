export const main_render = [
  { component: "document-title", children: "Hotellinformation" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "Thomas Panzio Bed and Breakfast ligger 300 meter från järnvägsstationen Balatonfured, och erbjuder närhet till Jokai kilato. Boendet består av 15 rum.",
        "Magyar Kiralyok Panoptikuma & Kalozmuzeum är 4,6 km från hotellet, medan Madarles Csopak är 4,7 km bort. Det är beläget ca 1 km till centrala Balatonfüred.Jokai Mor Memorial House ligger inom gångavstånd från detta hotell.",
        "Hotellet erbjuder centralvärme, privat balkong och skafferi i rummen.",
        "I baren kan gästerna få frukost. Peron Pizzéria & Pub och Halaszkert finns bland matställen i området.Busshållplatsen Széchenyi sétány är belägen cirka 500 meter från boendet.Hotellet innehåller också rökområde och parkering.",
        {
          color: "brand.red",
          children: "Viktig information",
          fontSize: "xl",
        },
        [
          { component: "b", children: "Incheckning: " },
          "från 15:00-18:00",
          { component: "br" },
          { component: "b", children: "Utcheckning: " },
          "senast 08:00-10:00",
        ],
        [
          { component: "b", children: "Barn och extrasängar" },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Äldre barn/vuxna bor gratis på hotellet och sover i extrasäng",
                "Alla barn under 2 år bor gratis i barnsäng.",
              ],
            },
          },
        ],
        "När du checkar in måste du uppvisa giltigt foto-ID och kreditkort. På grund av coronaviruset (covid-19) finns det för närvarande extra säkerhets- och hygienprocedurer på det här boendet.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
