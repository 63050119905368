export const info_side_render = {
  component: {
    component: "Box",
    w: { base: "100%", lg: "16rem" },
    pl: { lg: 4 },
    pt: { base: 4, lg: 0 },
    children: { component: "wrap", mb: 2 },
    fontSize: "sm",
    __define: {
      default: {
        component: "Box",
      },
      ul: {
        __key: "ul",
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "ul-stack": {
        __key: "ul-stack",
        component: "ul",
        children: {
          component: "wrap",
          wrap: {
            component: "HStack",
            justify: "space-between",
            children: { component: "wrap" },
          },
        },
      },
    },
  },
  children: [
    { component: "b", children: "Thomas Panzió" },
    "20 Horváth Mihály utca, Balatonfüred, Magyarország",
    {
      my: 4,
      component: "HStack",
      justify: "space-between",
      textTransform: "uppercase",
      w: "100%",
      borderBottom: "1px solid",
      borderTop: "1px solid",
      borderColor: "brand.red",
      p: 1,
      children: [
        {
          component: "Text",
          children: "Thomas Panzió",
          color: "brand.red",
        },
        {
          component: "Link",
          to: "/elerhetoseg",
          children: {
            component: "Button",
            as: "div",
            colorScheme: "brand.dred-scheme",
            children: "Pokaż dużą mapę",
            size: "xs",
          },
        },
      ],
    },
    { color: "brand.red", children: "Co znajduje się w pobliżu?" },
    [
      { component: "b", children: "Lokalne atrakcje" },
      {
        component: "ul-stack",
        children: [
          ["Hit Gyulekezete Balatonfured", "450\u00A0m"],
          ["Okrągły Kościół", "750\u00A0m"],
          ["Bergmann Confectionery Tea and Coffee", "650\u00A0m"],
          ["Jokai Mor Memorial House", "650\u00A0m"],
          ["Helka Music Club", "500\u00A0m"],
          ["Radio TV Muzeum", "450\u00A0m"],
          ["Csok", "700\u00A0m"],
          ["Round Church", "700\u00A0m"],
        ],
      },
    ],
    [
      { component: "b", children: "Lotniska" },
      {
        component: "ul-stack",
        children: [["Lotnisko Heviz-Balaton", "78.3\u00A0km"]],
      },
    ],
    [
      { component: "b", children: "Stacje kolejowe" },
      {
        component: "ul-stack",
        children: [["Balatonfüred train station", "300\u00A0m"]],
      },
    ],
  ],
};
