import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import LeftBar from "./LeftBar";

export default function ToggleMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen((o) => !o);
  return (
    <Box display={{ md: "none" }} mt={0}>
      {isOpen && <LeftBar />}
      <Box
        onClick={toggle}
        width="100%"
        textAlign="center"
        fontSize="1.5em"
        py={1}
      >
        {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Box>
    </Box>
  );
}
