export const rooms_render = [
  { component: "document-title", children: "Szobák" },
  {
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      multiUl: {
        component: "Stack",
        color: "gray.700",
        spacing: { base: 0, md: 4 },
        direction: { base: "column", md: "row" },
        children: {
          component: "wrap",
          wrap: {
            component: "Box",
            children: { component: "ul" },
            flex: 1,
          },
        },
      },
      roomTitle: {
        component: "Text",
        w: "100%",
        borderBottom: "1px solid",
        borderColor: "brand.gray",
        color: "gray.600",
        fontSize: "xl",
        mt: 6,
      },
    },
    component: "Box",
    flex: 1,
    children: [
      {
        component: "Text",
        children: "Galéria",
        color: "brand.red",
        fontSize: "3xl",
        mb: 4,
      },
      { component: "roomTitle", children: "Deluxe A" },
      {
        component: "multiUl",
        children: [
          ["Tóra néző", "Ingyenes Wifi", "Ingyenes parkoló"],
          [
            "Zárható bicikli tároló",
            "Elektromos bicikli bérlés (5000 Ft / nap)",
          ],
        ],
      },
      { component: "roomTitle", children: "Deluxe B" },
      {
        component: "multiUl",
        children: [
          ["Városra néző", "Ingyenes Wifi", "Ingyenes parkoló"],
          [
            "Zárható bicikli tároló",
            "Elektromos bicikli bérlés (5000 Ft / nap)",
          ],
        ],
      },
      { component: "roomTitle", children: "Family apartman" },
      {
        component: "multiUl",
        children: [
          ["Városra néző", "Ingyenes Wifi", "Ingyenes parkoló"],
          [
            "Zárható bicikli tároló",
            "Elektromos bicikli bérlés (5000 Ft / nap)",
          ],
        ],
      },
      { component: "roomTitle", children: "Standard A" },
      {
        component: "multiUl",
        children: [
          ["Városra néző", "Ingyenes Wifi", "Ingyenes parkoló"],
          [
            "Zárható bicikli tároló",
            "Elektromos bicikli bérlés (5000 Ft / nap)",
          ],
        ],
      },
      { component: "roomTitle", children: "Standard B" },
      {
        component: "multiUl",
        children: [
          ["Városra néző", "Ingyenes Wifi", "Ingyenes parkoló"],
          [
            "Zárható bicikli tároló",
            "Elektromos bicikli bérlés (5000 Ft / nap)",
          ],
        ],
      },
    ],
  },
];
