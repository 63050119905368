import { Box, Image, Link as ChakraLink, VStack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import kfaludy from "./kfaludy.png";

function MenuItem(props) {
  return (
    <ChakraLink
      as={Link}
      borderBottom="1px solid"
      borderColor="brand.red"
      borderRadius={0}
      justifyContent="left"
      color="brand.red"
      w="100%"
      py={2}
      px={0}
      _focus={{
        shadow: "none",
      }}
      fontWeight="bold"
      variant="solid"
      bg="white"
      {...props}
    />
  );
}

export default function LeftBar() {
  const { t } = useTranslation();
  return (
    <VStack align="start" spacing={0}>
      <MenuItem to="/">{t("menu.main")}</MenuItem>
      <MenuItem to={t("url.rooms")}>{t("menu.rooms")}</MenuItem>
      <MenuItem to={t("url.services")}>{t("menu.services")}</MenuItem>
      <MenuItem to={t("url.gallery")}>{t("menu.gallery")}</MenuItem>
      <MenuItem to={t("url.contact")}>{t("menu.contact")}</MenuItem>
      <MenuItem to="/kisfaludy">{t("menu.kisfaludy")}</MenuItem>
      <Box pt={{ base: 5, lg: 10 }} w="100%">
        <Box mx="auto">
          <Link to={"/kisfaludy"}>
            <Image src={kfaludy} mx="auto" w="100%" maxW="15em" />
          </Link>
        </Box>
      </Box>
    </VStack>
  );
}
