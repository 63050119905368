import React from "react";

export const JsonRendererContext = React.createContext();

const defaultComponents = {
  wrap: WrapComponent,
};

function getLocalComponents(defines) {
  if (!defines) return null;
  return Object.keys(defines).reduce(
    (p, c) => ({
      ...p,
      [c]: createVirtualRenderComponent(defines[c]),
    }),
    {}
  );
}

function renderTemplate(template, components = {}) {
  if (template == null) return null;
  if (typeof template == "function") template = template({ components });
  if (Array.isArray(template))
    return template.map((t, i) =>
      renderTemplate(
        !t || Array.isArray(t) || typeof t != "object" ? t : { ...t, key: i },
        components
      )
    );
  if (typeof template != "object") return template;
  if (template.$$typeof) return template;

  // -------------------------
  // Rendering object template
  // -------------------------

  const localComponents = getLocalComponents(template?.__define);
  if (localComponents)
    components = {
      ...components,
      ...localComponents,
    };

  const findComponent = (comp) => {
    if (components && components[comp]) return components[comp];
    return defaultComponents[comp];
  };

  let Component = null;
  // Render default component
  if (!template.component) Component = findComponent("default");
  // Render defined component
  else if (
    typeof template.component == "string" &&
    findComponent(template.component)
  )
    Component = findComponent(template.component);
  // Render string component
  else if (typeof template.component == "string")
    Component = template.component;
  // Render template component
  else Component = createVirtualRenderComponent(template.component);

  if (!Component) return null;

  const children =
    !Component || !Component.raw_children
      ? renderTemplate(template.children, components)
      : template.children;

  if (typeof Component == "string")
    return React.createElement(
      Component,
      { ...template, component: null, children: null },
      children
    );

  if (Component.raw_call)
    return Component({ ...template, __define: null, children, components });

  const compData = {
    ...template,
    children,
  };
  delete compData.component;
  delete compData.__defime;
  return <Component {...compData} />;
}

function createVirtualRenderComponent(template) {
  const VirtualComponent = ({
    children,
    components,
    __define: _,
    component: ___,
    ...props
  }) => {
    return renderVirtualComponent(
      { ...template, ...props },
      components,
      children
    );
  };
  VirtualComponent.raw_call = true;
  VirtualComponent.raw_children = true;

  return VirtualComponent;
}

function renderVirtualComponent(template, components, children) {
  if (template == null) return null;
  if (Array.isArray(template))
    return template.map((t) => renderVirtualComponent(t, components, children));
  if (typeof template != "object") return template;

  if (Object.keys(template).includes("children"))
    return renderTemplate(
      {
        ...template,
        children: ({ components }) =>
          renderVirtualComponent(template.children, components, children),
      },
      components
    );

  return renderTemplate(
    {
      ...template,
      children,
    },
    components
  );
}

function JsonRenderer({ render, components = {} }) {
  return (
    <JsonRendererContext.Provider
      value={components}
      children={renderTemplate(render, components)}
    />
  );
}

function WrapComponent({ children, components, wrap, ...props }) {
  if (!children) return null;
  if (typeof children == "function") children = children({ components });
  if (!Array.isArray(children)) children = [children];

  const mapped = children.map((c, i) =>
    renderTemplate(
      { ...props, children: c, component: wrap, key: i },
      components
    )
  );

  return mapped;
}
WrapComponent.raw_children = true;
WrapComponent.raw_call = true;

export default React.memo(JsonRenderer);
