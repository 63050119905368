export const service_az_render = {
  __define: {
    ul: {
      component: "UnorderedList",
      children: {
        component: "wrap",
        wrap: "ListItem",
      },
    },
  },
  children: [
    {
      children: "Reggeli ajánlat (térítés ellenében)",
      component: "Text",
      as: "h2",
      fontSize: "2xl",
      mt: 4,
      color: "brand.red",
    },
    {
      component: "p",
      children:
        "Szendvics – sonkás v. szalámis v. sajtos – feltétek variálhatóak; vajjal megkent zsemle benne a kívánt feltét valamint primőr zöldség ",
    },
    {
      component: "p",
      children:
        "A földszinti kebab bárban lehetőség van reggel 8-tól meleg gyorsételek fogyasztására",
    },
    {
      children: "Szolgáltatási tájékoztató",
      component: "Text",
      as: "h2",
      fontSize: "2xl",
      mt: 4,
      color: "brand.red",
    },
    {
      component: "ul",
      children: [
        [
          {
            component: "b",
            children: "24 órás biztonságos  csomag megőrzés - ",
          },
          "érkező és távozó vendégek részére. Igényét jelezze a recepción.",
        ],
        [
          {
            component: "b",
            children: "Ágynemű  - ",
          },
          "heti minimum kétszeri cseréje.",
        ],
        [
          {
            component: "b",
            children: "Ágynemű  - ",
          },
          "Napi cseréje kérés esetén, térítés ellenében, igényét jelezze a recepción vagy a személyzetnél.",
        ],
        [
          {
            component: "b",
            children: "Cipőtisztító gép - ",
          },
          "A recepción található és használható.",
        ],
        [
          {
            component: "b",
            children: "Dohányzás - ",
          },
          "Csak a kijelölt helyeken, a kijelölt helyekről tájékoztatás a recepción.",
        ],
        [
          {
            component: "b",
            children: "Esernyő - ",
          },
          "A recepción áll rendelkezésre, igényét jelezze a személyzetnél.",
        ],
        [
          {
            component: "b",
            children: "Etetőszék - ",
          },
          "Kérésre díjtalanul biztosítjuk.",
        ],
        [
          {
            component: "b",
            children: "Étterem - ",
          },
          "Gyors étterem Kebab bár  8-10 óráig.",
        ],
        [
          {
            component: "b",
            children: "Fizető eszközök - ",
          },
          "Készpénz (forint,), bankkártya, hitelkártya, Szép kártya,(OTP, MKB, K&H. Szállás hely, vendéglátás, szabadidő. átutalás.",
        ],
        [
          {
            component: "b",
            children: "Hajszárító - ",
          },
          "Minden szobában található.",
        ],
        [
          {
            component: "b",
            children: "Internet, wifi szolgáltatás - ",
          },
          "Minden szobában és a közösségi területeken díjtalan. Panziónkban wifi erősítő van elhelyezve. A jelszóval minden erősítőhöz tud csatlakozni. Jelszó: BJH4H6MRLBR. HUAWEI B535-A723.",
        ],
        [
          {
            component: "b",
            children: "Kerékpár kölcsönzés - ",
          },
          "Panziónk 10 - saját elektromos biciklivel rendelkezik. Igényét jelezze a recepción, térítés ellenében igénybe vehető szolgáltatás. ",
        ],
        [
          {
            component: "b",
            children: "Késői kijelentkezés - ",
          },
          "Térítés ellenében, lehetőség szerint, igényét jelezze a recepción. Legkésőbbi kijelentkezés ideje 17 óra.",
        ],
        [
          {
            component: "b",
            children: "Kijelentkezés - ",
          },
          "Távozás napján 10: óráig.",
        ],
        [
          {
            component: "b",
            children: "Kulcskártya - ",
          },
          "Szobáink zárjai biztonsági zárasok.",
        ],
        [
          {
            component: "b",
            children: "Légkondicionáló - ",
          },
          "Fűtő, hűtő, ventilátor funkció ",
        ],
        [
          {
            component: "b",
            children: "Nyomtatási szolgáltatás - ",
          },
          "Térítés ellenében igényelhető szolgáltatás, igényét jelezzea recepción.",
        ],
        [
          {
            component: "b",
            children: "Parkolás - ",
          },
          "A panzió saját  parkolójában limitált számban parkolási lehetőség vendégeinknek díjat nem számítunk fel.",
        ],
        [
          {
            component: "b",
            children: "Párna - ",
          },
          "Kérésre, igényét jelezze a recepción vagy a személyzetnél.",
        ],
        [
          {
            component: "b",
            children: "Pótágy, Gyermek utazóágy - ",
          },
          "Ingyenes szolgáltatás, igényét jelezze a recepción.",
        ],
        [
          {
            component: "b",
            children: "Programok - ",
          },
          "Az aktuális környéki programokról  érdeklődjön a recepción , vagy keresse a prospektusokat a szobákban elhelyezve.",
        ],
        [
          {
            component: "b",
            children: "Rádió - ",
          },
          "Tv-n keresztül.",
        ],
        [
          {
            component: "b",
            children: "Recepció - ",
          },
          "Nyitva 8:00 – 22 óráig.",
        ],
        [
          {
            component: "b",
            children: "Régiós étele, ízek borok - ",
          },
          "szomszédos étteremben megtalálhatók.",
        ],
        [
          {
            component: "b",
            children: "Strandcikkek, gumimatracok - ",
          },
          "személyzetünktől igényelhető.",
        ],
        [
          {
            component: "b",
            children: "Szobához igényelhető (mobil) telefon - ",
          },
          "A recepción jelezze igényét. ",
        ],
        [
          {
            component: "b",
            children: "Takarítás - ",
          },
          "Szobákat minden nap takarítjuk. Igény szerint  igénybe vehető szolgáltatás.",
        ],
        [
          {
            component: "b",
            children: "Takaró, Párna - ",
          },
          "A szobákban megtalálható ágyneműn felül, igényét jelezze a recepción vagy a személyzetnél.",
        ],
        [
          {
            component: "b",
            children: "Televízió, Smart TV - ",
          },
          "Távirányító a szobákban elhelyezve. Technikai segítséget szívesen nyújtanak munkatársaink.",
        ],
        [
          {
            component: "b",
            children: "Tisztálkodási szerek - ",
          },
          "Kínálatunkat megtalálja a recepción ( fogkefe, fogkrém, borotválkozási szerek) ",
        ],
        [
          {
            component: "b",
            children: "Törölköző - ",
          },
          "A szobába bekészítve kis- és nagyalakú. 3-os cseréje igény szerint fizetés ellenében. ",
        ],
        [
          {
            component: "b",
            children: "Varró készlet - ",
          },
          "Kérésre a recepción.",
        ],
        [
          {
            component: "b",
            children: "Vasaló - ",
          },
          "A recepción igényelhető.",
        ],
      ],
    },
  ],
};
