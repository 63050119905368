import { Stack } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import JsonRenderer from "../../../../../Workspace/thomas_panzio/ui/src/components/JsonRenderer";
import { jsonRendererComponents } from "../jsonRendererComponents";

export default function I18nPage({ path }) {
  const { t } = useTranslation();
  const render = t(path, { returnObjects: true });
  return (
    <Stack w="100%" direction={{ base: "column", lg: "row" }} flex={1}>
      <JsonRenderer render={render} components={jsonRendererComponents} />
    </Stack>
  );
}
