import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";
import { default as React, useRef } from "react";

export const PopoverInputContext = React.createContext();

export default function PopoverInput({ value, children, title }) {
  const disclosure = useDisclosure();
  const { onOpen, onClose, isOpen } = disclosure;

  const initialFocusRef = useRef();
  return (
    <PopoverInputContext.Provider value={disclosure}>
      <Popover
        initialFocusRef={initialFocusRef}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement="bottom"
        closeOnBlur={true}
      >
        <PopoverTrigger>
          <InputGroup>
            <Input
              as="span"
              cursor="pointer"
              bg="white"
              color="black"
              children={value}
              display="flex"
              alignItems="center"
            />
            <InputRightElement
              cursor="pointer"
              children={<TriangleDownIcon color="black" />}
            />
          </InputGroup>
        </PopoverTrigger>
        <PopoverContent color="black">
          <PopoverHeader pt={4} fontWeight="bold" border="0">
            {title}
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>{children}</PopoverBody>
        </PopoverContent>
      </Popover>
    </PopoverInputContext.Provider>
  );
}
