import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Select,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ErrorMessage, Field, Formik } from "formik";
import { default as React, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PopoverInputContext } from "../PopoverInput";
import { validationSchema_1 } from "./validation";

export default function OfferFormParticipantInput({ form }) {
  const { t } = useTranslation();
  const vSchema = useMemo(() => validationSchema_1(t), [t]);
  return (
    <PopoverInputContext.Consumer>
      {({ onClose }) => (
        <Formik
          enableReinitialize={true}
          initialValues={form.values}
          onSubmit={(values) => {
            form.setValues(values);
            onClose();
          }}
          validationSchema={vSchema}
        >
          {(form) => (
            <Box>
              <HStack>
                <FormControl>
                  <FormLabel>{t("offer-form.participant_adults")}</FormLabel>
                  <Field as={Select} name="adults">
                    <OptionRange start="1" end="4" />
                  </Field>
                </FormControl>
                <FormControl>
                  <FormLabel>{t("offer-form.participant_children")}</FormLabel>
                  <Field
                    as={Select}
                    name="children"
                    onChange={(e) => {
                      form.setFieldValue("children", e.target.value);
                      const oa = form.values.children_ages;
                      form.setFieldValue(
                        "children_ages",
                        [
                          ...oa,
                          ...Array(
                            oa.length < e.target.value
                              ? e.target.value - oa.length
                              : 0
                          ).fill(""),
                        ].slice(0, e.target.value)
                      );
                    }}
                  >
                    <OptionRange end="3" />
                  </Field>
                </FormControl>
              </HStack>
              {form.values.children > 0 && (
                <FormControl>
                  <FormLabel>
                    {t("offer-form.participant_children_ages")}
                  </FormLabel>
                  <HStack justify="start">
                    {Array(parseInt(form.values.children))
                      .fill(0)
                      .map((_, i) => (
                        <Field
                          as={Select}
                          w="auto"
                          name={`children_ages[${i}]`}
                          key={i}
                          required
                        >
                          <option value="">-</option>
                          <option value="0">&lt;1</option>
                          <OptionRange start={1} end={16} />
                        </Field>
                      ))}
                  </HStack>
                  <VStack justify="start">
                    {Array(parseInt(form.values.children))
                      .fill(0)
                      .map((_, i) => (
                        <ErrorMessage
                          key={i}
                          component={Text}
                          color="brand.red"
                          fontSize="sm"
                          name={`children_ages[${i}]`}
                        />
                      ))}
                  </VStack>
                </FormControl>
              )}
              <HStack justify="space-between" mt={4}>
                <Button
                  type="button"
                  onClick={() => {
                    form.resetForm();
                    onClose();
                  }}
                  name=""
                  colorScheme="brand.dred-scheme"
                >
                  {t("offer-form.participant_btn_cancel")}
                </Button>
                <Button
                  type="button"
                  onClick={form.submitForm}
                  name=""
                  colorScheme="brand.dred-scheme"
                >
                  {t("offer-form.participant_btn_ok")}
                </Button>
              </HStack>
            </Box>
          )}
        </Formik>
      )}
    </PopoverInputContext.Consumer>
  );
}

function OptionRange({ start = 0, end = 10 }) {
  start = parseInt(start);
  end = parseInt(end);
  return Array(end - start + 1)
    .fill("")
    .map((_, i) => (
      <option key={i + start} value={i + start}>
        {i + start}
      </option>
    ));
}
