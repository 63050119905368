export const main_render = [
  { component: "document-title", children: "Hotelinformatie" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "Thomas Panzio Bed and Breakfast, gesitueerd op 4.6 km van Magyar Kiralyok Panoptikuma & Kalozmuzeum, verstrekt een rookruimte en parkeerplaatsen. De accommodatie bestaat uit 15 kamers.",
        "Jokai kilato ligt op een steenworp afstand, terwijl het treinstation Balatonfured op 5 minuten lopen van de locatie is. Het is slechts 10 minuten rijden van Madarles Csopak.Jokai Mor Memorial House ligt op loopafstand van dit hotel.",
        "Kamers in deze woning hebben een privé balkon, een eethoek en een voorraadkast.",
        "Het ontbijt wordt elke ochtend geserveerd in de bar. Er zijn Peron Pizzéria & Pub en Halaszkert 200 meter verderop.Széchenyi sétány busstation is op 500 meter afstand van de accommodatie.",
        {
          color: "brand.red",
          children: "Belangrijke informatie",
          fontSize: "xl",
        },
        [
          { component: "b", children: "Inchecken: " },
          "vanaf 15:00-18:00 uur",
          { component: "br" },
          { component: "b", children: "Uitchecken: " },
          "tot 08:00-10:00 uur",
        ],
        [
          { component: "b", children: "Kinderen en extra bedden" },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Oudere kinderen/volwassenen verblijven gratis in een extra bed.",
                "Alle kinderen jonger dan 2 jaar mogen gratis gebruik maken van een ledikant.",
              ],
            },
          },
        ],
        "Gasten zijn verplicht om een ​​geldig identiteitsbewijs met foto en een creditcard te tonen bij het inchecken. Houd er rekening mee dat alle Speciale Verzoeken afhankelijk zijn van beschikbaarheid en mogelijk extra kosten met zich meebrengen. Vanwege het coronavirus (COVID-19) zijn er bij deze accommodatie extra veiligheids- en sanitaire maatregelen van toepassing.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
