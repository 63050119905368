export const services_render = [
  { component: "document-title", children: "Facilities" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        children: "Facilities",
        component: "Text",
        as: "h2",
        fontSize: "2xl",
        mb: 4,
        color: "brand.red",
      },
      {
        children: "General",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "No smoking on site",
            "Paid parking",
            "24-hour reception",
            "VIP check-in/ -out",
          ],
          [
            "No pets allowed",
            "Rooms/ Facilities for disabled",
            "Security",
            "Fire extinguishers",
          ],
        ],
      },
      {
        children: "Leisure & Sports",
        component: "separator",
      },
      {
        component: "info-block",
        children: [["Entertainment", "Bar/ Lounge area"], ["Cycling"]],
      },
      {
        children: "Services",
        component: "separator",
      },
      {
        component: "info-block",
        children: ["Airport shuttle", "Bicycle hire"],
      },
      {
        children: "Room Amenities",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Air conditioning",
            "Heating",
            "Mini-bar",
            "Terrace",
            "Dining table",
          ],
          [
            "Sink",
            "Flat-screen TV",
            "DVDs/Videos for children",
            "Carpeted floor",
          ],
        ],
      },
      {
        component: "i18n",
        path: "page.service_az",
      },
    ],

    __define: {
      separator: {
        w: "100%",
        color: "gray",
        borderBottom: "1px solid gray",
      },
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "info-block": {
        w: "100%",
        mb: 4,
        component: "Stack",
        direction: "row",
        children: {
          component: "wrap",
          wrap: {
            w: "50%",
            children: {
              component: "ul",
            },
          },
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
