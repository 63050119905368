export const services_render = [
  { component: "document-title", children: "Ausstattung" },
  {
    component: "Box",
    flex: 1,
    children: [
      {
        children: "Ausstattung",
        component: "Text",
        as: "h2",
        fontSize: "2xl",
        mb: 4,
        color: "brand.red",
      },
      {
        children: "Allgemeines",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          [
            "Alle Bereiche sind Nichtraucherzonen",
            "Parkplätze gegen Aufpreis",
            "24-Stunden-Rezeption",
            "VIP check-in/ -out",
          ],
          [
            "Keine Haustiere erlaubt",
            "Behindertengerechte Zimmer/ Einrichtungen",
            "Sicherheitsdienst",
            "Feuerlöscher",
          ],
        ],
      },
      {
        children: "Sport und Freizeit",
        component: "separator",
      },
      {
        component: "info-block",
        children: [["Unterhaltung", "Bar-/Loungebereich"], ["Radfahren"]],
      },
      {
        children: "Dienstleistungen",
        component: "separator",
      },
      {
        component: "info-block",
        children: ["Flughafen-Shuttle", "Fahrradverleih"],
      },
      {
        children: "Annehmlichkeiten im Zimmer",
        component: "separator",
      },
      {
        component: "info-block",
        children: [
          ["Klimaanlage", "Heizung", "Minibar", "Terrasse", "Esstisch"],
          [
            "Waschbecken",
            "Flachbildschirm-TV",
            "DVDs/Videos für Kinder",
            "Teppichböden",
          ],
        ],
      },
      {
        component: "i18n",
        path: "page.service_az",
      },
    ],

    __define: {
      separator: {
        w: "100%",
        color: "gray",
        borderBottom: "1px solid gray",
      },
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
      "info-block": {
        w: "100%",
        mb: 4,
        component: "Stack",
        direction: "row",
        children: {
          component: "wrap",
          wrap: {
            w: "50%",
            children: {
              component: "ul",
            },
          },
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
