import { contact_render } from "./pages/contact";
import { gallery_render } from "./pages/gallery";
import { info_side_render } from "./pages/info_side";
import { main_render } from "./pages/main";
import { rooms_render } from "./pages/rooms";
import { services_render } from "./pages/services";
import { service_az_render } from "./pages/service_az";

const hu = {
  langs: [
    { icon: "hu", lang: "hu-HU", name: "Magyar" },
    { icon: "gb", lang: "en-GB", name: "English" },
    { icon: "pl", lang: "pl-PL", name: "Polski" },
    { icon: "de", lang: "de-DE", name: "Deutsch" },
    { icon: "nl", lang: "nl-NL", name: "Nederlands" },
    { icon: "dk", lang: "da-DK", name: "Dansk" },
    { icon: "se", lang: "sv-SE", name: "Svenska" },
  ],
  url: {
    contact: "/elerhetoseg",
    gallery: "/galeria",
    rooms: "/szobak",
    services: "/szolgaltatasok",
  },
  menu: {
    main: "Szállás",
    rooms: "Szobák",
    services: "Szolgáltatások",
    gallery: "Galéria",
    contact: "Elérhetőség",
    kisfaludy: "Kisfaludy Program",
  },
  layout: {
    name: "THOMAS PANZIÓ BALATONFÜRED",
    address: "Balatonfüred, Horváth Mihály u. 20",
  },
  page: {
    info_side: info_side_render,
    main: main_render,
    services: services_render,
    contact: contact_render,
    gallery: gallery_render,
    rooms: rooms_render,
    service_az: service_az_render,
  },
  "offer-form": {
    title: "Ajánlatkérés",
    arrival: "Érkezés napja",
    leaving: "Távozás napja",
    participant: "Résztvevők",
    participant_format_1: "{adults} Felnőtt",
    participant_format_2: "{adults} Felnőtt + {children} Gyerek",
    participant_title: "Résztvevők beállítása",
    participant_btn_ok: "Ok",
    participant_btn_cancel: "Mégsem",
    participant_adults: "Felnőtt",
    participant_children: "Gyerek",
    participant_children_ages: "Gyerekek életkora",
    participant_children_ages_error: "A gyerekek életkorának megadása kötelező",
    btn_ok_1: "Ajánlatkérés",
    modal_title: "Ajánlatkérés",
    modal_name: "Név",
    modal_name_required: "Név megadása kötelező",
    modal_email: "Email",
    modal_email_required: "Email cím megadása kötelező",
    modal_email_matches: "Email cím formátuma hibás",
    modal_phone: "Telefonszám",
    modal_phone_matches: "A telefonszám formátuma hibás",
    modal_phone_min: "A telefonszám formátuma hibás",
    modal_phone_required: "A telefonszám megadása kötelező",
    modal_room: "Szoba típus",
    modal_message: "Megjegyzés",
    modal_acceptterms: [
      "Az ",
      {
        component: "Text",
        as: "a",
        color: "brand.red",
        href: "/adatkezelesi.pdf",
        children: "adatkezelési tájékoztatót",
      },
      " elfogadom.",
    ],
    modal_acceptterms_required:
      "Az adatkezelési tájékoztató elfogadása kötelező!",
    modal_btn_cancel: "Vissza",
    modal_btn_ok: "Küldés",
    success_title: "Sikeres ajánlatkérés",
    success_btn_ok: "Ok",
    success_text: "Kérését rögzítettük, munkatársunk hamarosan keresi önt.",
  },
};
export default hu;
