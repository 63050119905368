export const main_render = [
  { component: "document-title", children: "Főoldal" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "A Thomas Panzio Panzió egy kedvező árú szállás a Kiserdő szomszédságában. Az ingatlan 15 szobából áll.",
        "A vendégek ellátogathatnak az Apáti templom területére Balatonfüred városában, mely 4.4 km-re van a szálláshelytől. A szállás továbbá 4.3 km-re helyezkedik el a Tihanyi Babamúzeum Kft. területétől.A Figula Pincészet szintén a szálloda közelében található.",
        "A szobák tartalmaznak központi fűtést, saját erkélyt és kamrát, továbbá rendelkeznek hűtővel.",
        "A bár mindennap nyitva áll reggelihez. A helyszíntől 200 méterre elhelyezkedő Peron Pizzéria & Pub és Halaszkert ételek széles választékát kínálják.Ez a hotel 300 méterre fekszik a Balatonfured vasútállomástól.A szálloda által biztosítva van dohányzásra kijelölt terület és parkoló.",
        { color: "brand.red", children: "Fontos információ", fontSize: "xl" },
        [
          { component: "b", children: "Bejelentkezés: " },
          "a következő időponttól 15:00-18:00 óra",
          { component: "br" },
          { component: "b", children: "Kijelentkezés: " },
          "a következő időpontig 08:00-10:00 óra",
        ],
        [
          { component: "b", children: "Gyermekek és pótágyak" },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Az idősebb gyermekek/felnőttek ingyenesen tartózkodhat(nak) pótágyon.",
                "Minden gyermek 2 éves kor alatt ingyenesen tartózkodhat(nak) kiságyban.",
                "A szobák pótágyazhatók.",
              ],
            },
          },
        ],
        "Bejelentkezéskor személy igazolvány és lakcímkártya felmutatása szükséges. Kérjük, vegye figyelembe, hogy a különleges kérések teljesítése érkezéskor, a rendelkezésre állás függvényében történik, és felár fizetését vonhatja maga után. Ez a szállás a koronavírus (COVID-19) helyzetre reagálva biztonsági és egészségügyi óvintézkedéseket alkalmaz.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
