import { Box, Image } from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";

const carouselHeight = { base: "15em", sm: "22.5em", md: "27.5em" };

export default function RemoteCarousel({ apiKey }) {
  const apiUrl = apiKey && "/api/carousel?key=" + apiKey;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    if (apiUrl)
      axios
        .get(apiUrl)
        .then((r) => {
          if (r.data[0]) {
            setData(r.data[0]);
            setIsLoading(false);
            console.log(r.data[0]);
          }
        })
        .catch((r) => {});
  }, [setIsLoading, apiUrl]);

  return (
    <Box w="100%" h={carouselHeight} overflow="hidden">
      {!isLoading && !!data && data?.images?.length > 0 && (
        <Carousel
          showIndicators={false}
          showThumbs={false}
          autoPlay
          infiniteLoop
        >
          {!!data &&
            data.images.map((img, i) => <ImageRender key={i} data={img} />)}
        </Carousel>
      )}
    </Box>
  );
}

function ImageRender({ data }) {
  const [isLoaded, setIsLoaded] = useState(false);

  if (!data || !data.image) return null;

  return (
    <div>
      <Image
        w="100%"
        h={carouselHeight}
        src={data.image.filename}
        alt={data.alt}
        display={isLoaded ? "block" : "none!important"}
        objectFit="cover"
        onLoad={() => setIsLoaded(true)}
      />
      {data.caption && <p className="legend">{data.caption}</p>}
    </div>
  );
}
