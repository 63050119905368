import { contact_render } from "./pages/contact";
import { gallery_render } from "./pages/gallery";
import { info_side_render } from "./pages/info_side";
import { main_render } from "./pages/main";
import { rooms_render } from "./pages/rooms";
import { services_render } from "./pages/services";

const hu = {
  url: {
    contact: "/elerhetoseg",
    gallery: "/galeria",
    rooms: "/szobak",
    services: "/szolgaltatasok",
  },
  menu: {
    main: "Hotellinformation",
    rooms: "Rum",
    services: "Faciliteter",
    gallery: "Galleri",
    contact: "Läge",
    kisfaludy: "Kisfaludy Program",
  },
  layout: {
    name: "THOMAS PANZIÓ BALATONFÜRED",
    address: "Balatonfüred, Horváth Mihály u. 20",
  },
  page: {
    info_side: info_side_render,
    main: main_render,
    services: services_render,
    contact: contact_render,
    gallery: gallery_render,
    rooms: rooms_render,
  },
  "offer-form": {
    title: "Online bokning",
    arrival: "Incheckning",
    leaving: "Utcheckning",
    participant: "Gäster",
    participant_format_1: "{adults} Vuxna",
    participant_format_2: "{adults} Vuxna + {children} Barn",
    participant_title: "Set Guests",
    participant_btn_ok: "Ok",
    participant_btn_cancel: "Avbryt",
    participant_adults: "Vuxna",
    participant_children: "Barn",
    participant_children_ages: "Barnens åldrar",
    participant_children_ages_error: "Ange barnets ålder",
    btn_ok_1: "Online bokning",
    modal_title: "Online bokning",
    modal_name: "Name",
    modal_name_required: "Name is required",
    modal_email: "Email",
    modal_email_required: "Email address is required",
    modal_email_matches: "Email address is incorrect",
    modal_phone: "Phone number",
    modal_phone_matches: "Phone number is incorrect",
    modal_phone_min: "Phone number is incorrect",
    modal_phone_required: "Phone number is required",
    modal_room: "Room type",
    modal_message: "Comment",
    modal_acceptterms: [
      "I have read and accepted the ",
      {
        component: "Text",
        as: "a",
        color: "brand.red",
        href: "/adatkezelesi.pdf",
        children: "Privacy Policy",
      },
      ".",
    ],
    modal_acceptterms_required: "You have to accept the Privacy Policy",
    modal_btn_cancel: "Avbryt",
    modal_btn_ok: "Send",
    success_title: "Online reservation is success",
    success_btn_ok: "Ok",
    success_text:
      "Your request has been recorded and our staff will contact you shortly.",
  },
};
export default hu;
