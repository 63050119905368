import {
  AspectRatio,
  Box,
  Grid,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Gallery({
  apiKey,
  templateColumns = "repeat(5, 1fr)",
  gap = 4,
}) {
  const apiUrl = apiKey && "/api/carousel?key=" + apiKey;

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    if (apiUrl)
      axios
        .get(apiUrl)
        .then((r) => {
          if (r.data[0]) {
            setData(r.data[0]);
            setIsLoading(false);
            console.log(r.data[0]);
          }
        })
        .catch((r) => {});
  }, [setIsLoading, apiUrl]);

  const [selectedImage, setSelectedImage] = useState(null);

  console.log("gallery", data);

  return (
    <Box w="100%">
      <Modal
        isCentered
        isOpen={!!selectedImage}
        onClose={() => setSelectedImage(null)}
      >
        <ModalOverlay />
        <ModalContent maxW="container.lg">
          <ModalCloseButton />
          <ModalBody p={2}>
            <Image src={selectedImage} w="100%" />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Grid templateColumns={templateColumns} gap={gap}>
        {!isLoading &&
          !!data &&
          data?.images?.length > 0 &&
          data.images.map((img, i) => (
            <ImageRender
              key={i}
              data={img}
              onSelect={() => setSelectedImage(img.image.filename)}
            />
          ))}
      </Grid>
    </Box>
  );
}

function ImageRender({ data, onSelect }) {
  const [isLoaded, setIsLoaded] = useState(false);

  if (!data || !data.image) return null;

  return (
    <Box bg="gray.200">
      <AspectRatio ratio={4 / 3}>
        <Image
          w="100%"
          cursor="pointer"
          onClick={onSelect}
          src={data.image.filename}
          alt={data.alt}
          objectFit="cover"
          onLoad={() => setIsLoaded(true)}
          display={isLoaded ? "block" : "none!important"}
        />
      </AspectRatio>
    </Box>
  );
}
