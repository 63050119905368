import {
  Box,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";

function LanguageItem({ lang, name, showName = true, onSelect }) {
  return (
    <MenuItem onClick={() => onSelect && onSelect()}>
      <Image
        src={`https://flagcdn.com/24x18/${lang}.png`}
        srcSet={`https://flagcdn.com/48x36/${lang}.png 2x, https://flagcdn.com/72x54/${lang}.png 3x`}
        alt={name}
      />
      {showName ? <Text ml={4}>{name}</Text> : ""}
    </MenuItem>
  );
}

export default function LanguageSelector() {
  const { t, i18n } = useTranslation();

  const languages = t("langs", { returnObjects: true, fallbackLng: "hu-HU" });
  const currentLanguage = languages.find((x) => x.lang === i18n.language);

  return (
    <Menu>
      <MenuButton as={Box} variant="link">
        <LanguageItem showName={false} lang={currentLanguage.icon} />
      </MenuButton>
      <MenuList minW="5em">
        {languages.map((l) => (
          <LanguageItem
            key={`${l.lang}-${l.icon}`}
            lang={l.icon}
            name={l.name}
            onSelect={() => i18n.changeLanguage(l.lang)}
          />
        ))}
      </MenuList>
    </Menu>
  );
}
