export const main_render = [
  { component: "document-title", children: "Informacje o hotelu" },
  {
    component: "Box",
    flex: 1,
    children: {
      component: "wrap",
      mb: 2,
      children: [
        {
          component: "Text",
          fontSize: "2xl",
          children: "Thomas panzió",
          color: "brand.red",
        },
        "Budżetowy Thomas Panzio Bed and Breakfast powstał w pobliżu Jokai kilato. Obiekt oferuje parkingiem prywatnym na miejscu.",
        "Hotel znajduje się przy Jokai Mor Memorial House, 10 minut jazdy samochodem od Magyar Kiralyok Panoptikuma & Kalozmuzeum. Do Madarles Csopak można dostać się w ciągu 10 minut jazdy samochodem.",
        "We wszystkich pokojach znajdują się prywatny balkon, miejsce do jedzenia i spiżarnia.",
        "Bar jest doskonałym miejscem na poranny posiłek. Aby odwiedzić Peron Pizzéria & Pub i Halaszkert goście muszą przejść pieszo około 200 metrów od hotelu.5 minut pieszo do stacji kolejowej Balatonfured, która zapewnia bezpośrednie połączenie z lotniskiem Balaton (SOB- FlyBalaton).",
        {
          color: "brand.red",
          children: "Ważna informacja",
          fontSize: "xl",
        },
        [
          { component: "b", children: "Przyjazd: " },
          "od 15:00-18:00 godz.",
          { component: "br" },
          { component: "b", children: "Wyjazd: " },
          "do 08:00-10:00 godz.",
        ],
        [
          {
            component: "b",
            children: "Zakwaterowanie dzieci i dodatkowe łóżka",
          },
          {
            component: "UnorderedList",
            ml: 6,
            children: {
              component: "wrap",
              wrap: "ListItem",
              children: [
                "Starsze dzieci/dorośli mogą korzystać z dodatkowych łóżek za darmo.",
                "Wszystkie dzieci w wieku poniżej 2 roku/lat mogą spać w łóżeczkach niemowlęcych za darmo.",
              ],
            },
          },
        ],
        "W momencie zameldowania wymagane jest okazanie ważnego dowodu tożsamości ze zdjęciem oraz karty kredytowej. Życzenia specjalne zostaną zrealizowane w zależności od dostępności, a ich realizacja może podlegać dodatkowej opłacie. W odpowiedzi na koronawirusa (COVID-19) ten obiekt zapewnia obecnie dodatkowe środki bezpieczeństwa i środki sanitarne.",
      ],
    },
    __define: {
      ul: {
        component: "UnorderedList",
        children: {
          component: "wrap",
          wrap: "ListItem",
        },
      },
    },
  },
  {
    component: "i18n",
    path: "page.info_side",
  },
];
